@import '../../../../../styles/mixins';

.editable-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @include mobile() {
    margin-left: 8px;
  }

  div {
    height: 100%;
    width: 100%;
    .custom-spinner {
      top: 28%;
    }
  }
  .edit-content {
    display: flex;
    align-items: center;

    span {
      @include font(14px,500,22px);
      font-family: var(--roboto-med);
      color: var(--Gray-gray-9);
      white-space: nowrap;
      max-width: 400px;
      overflow: hidden !important;
      text-overflow: ellipsis;
      @include mobile() {
        max-width: 210px;
      }
    }

    input{
      @include font(14px,500,22px);
      font-family: var(--roboto-med);
      color: var(--Gray-gray-9);
      text-align: center;
      border: none;
      max-width: 700px;
      background-color: var(--Gray-gray-2);
      field-sizing: content;

      @include mobile() {
        background-color: var(--Gray-gray-1);
      }

      &:focus {
        outline: none;
        border: none;
        @include font(14px,400,2px);
        font-family: var(--roboto);
      }
    }

    .edit-icon {
      width: 28px;
      padding-left: 12px;
      &:hover > g >  path {
        fill: var(--Gray-gray-7);
      }
    }
  }
}
.editable-input-wrapper:has(.custom-spinner) {
  top: 34%;
}
