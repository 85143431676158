@import "../../../../common/styles/mixins";

.table-action {
  .ant-dropdown-menu {
    width: 216px;
    border-top: 1px solid var(--Gray-gray-4);
    padding: 10px 0 0 0;
    border-radius: 0px 0px 8px 8px!important;
    background: var(--Gray-gray-1);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);

    .ant-dropdown-menu-item {
      padding: 10px 24px;
      color: var(--accent-main, #748ADA);
      @include font(14px, 400, 16px);
      font-family: var(--roboto);
      &:last-child {
        color: var(--red-6);
      }
    }
  }
}
