.copy-tooltip {
  &__overlay {
    .ant-tooltip-inner {
      padding: 12px 8px;
      border-radius: 8px;
    }
    svg {
      margin-left: auto;
      float: right;
    }
    &__content {
      position: relative;
      color: var( --Gray-gray-9);
      cursor: pointer;
      padding-right: 16px;
    }
    &__text {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
      width:160px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: start;

    }
    &__icon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}