@import "../../styles/mixins";

.ant-drawer {
  .ant-drawer-header {
    background: var(--Gray-gray-1, #FFF);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }
  .ant-drawer-body {
    background: var(--Gray-gray-2, #F3F4F9);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 16px 40px 16px !important;
    .ant-form {
      padding: 8px;
    }
    button {
      width: 100%;
    }
  }
  .ant-drawer-close {
    margin-inline-end: 0;
    width: auto;
    height: auto;
  }
  .ant-drawer-title {
    margin-right: 8px;
    text-align: center;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}