@import '../../styles/mixins';

.select-language {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-dropdown-open {
    .arrow {
      transform:rotate(180deg);
    }
  }

  &__dropdown {
    .ant-dropdown-menu {
      right: 5px;
      box-shadow: none;
      border-radius: 2px;
      border: 1px solid var(--Gray-gray-4);
      background: var(--Neutral-1);
      &:hover {
        box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.10), 0px 4px 10px 0px rgba(0, 0, 0, 0.06), 0px 6px 16px 4px rgba(0, 0, 0, 0.05);
      }
      &-item {
        border-radius: 0 !important;
        span {
          @include font(14px,400,22px);
        }
        &:hover {
          background-color: var(--Neutral-1) !important;
        }
      }
    }
    &__container {
      transition: all .1s ease-in;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: var(--Gray-gray-9);
      padding-top: 4px;
      @include font(14px,400,24px);
      @include mobile() {
        color: var(--accent-main);
      }
      .flag {
        margin-right: 8px;
        width: 20px;
        height: 20px;
        @include mobile() {
          margin-right:8px;
        }
      }
      .arrow {
        margin-left: 7px;
        width: 10px;
        height: 10px;
        transition: all .5s linear;
        @include mobile() {
          margin-left: 7px;
          path {
            fill: var(--accent-main);
          }
        }
      }
    }
  }
}