@import "../../../styles/mixins";

.modal-delete {
  &-wrapper {
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 32px 32px 24px 32px;
  }

  &-content {
    padding: 0 0 0 34px;
    position: relative;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    margin: 0 0 16px 0;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    h6 {
      padding: 0;
      margin: 0 0 8px 0;
      color: var(--Gray-gray-9);
      font-family: var(--roboto-med)!important;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include mobile() {
        max-width: 230px;
      }
      @include font(16px, 500, 24px);
    }
    p {
      padding: 0;
      margin: 0;
      color: var(--Gray-gray-7);
      font-family: var(--roboto)!important;
      @include font(16px, 400, 22px);
      text-align: start;
    }

  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    button {
      height: 32px;
    }
  }
}
