@import "../../../common/styles/mixins";
@import "../../../common/styles/variables";

.drawer-layout {
  position: fixed;
  height: 100vh;
  width: 464px;
  top: 44px;
  //transition: all 0.25s ease-in-out;
  box-shadow: -10px 0px 30px 0px rgba(0, 0, 0, 0.10);
  background: var(--white);
  overflow-x: auto;
  z-index: 99;
  &:has(.drawer__transfer) {
    width: 440px;
  }

  .loader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    z-index: 12;
    .custom-spinner {
      top: 50%;
      left: 50%;
      width: 44px;
    }
  }

  &__top {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    background: var(--white);
    position: sticky;
    top: 0;
    margin-bottom: -5px;
    height: $close-section-height;
    padding: 0 12px;
    z-index: $z-index-close-section;
    transition: 0.2s;
    &._scrolled{
      height: $close-section-height-small;
    }

    @include mobile() {
      justify-content: space-between;
    }

    svg {
      cursor: pointer;
    }
  }
}
.drawer-layout:has(.drawer__transfer) {
  padding: 37px 16px;
  .drawer-layout__top {
    position: relative;
    height: 15px;
    padding: 0;
    svg {
      position: absolute;
      top: -5px;
      right: -3px;
    }
  }
}

.drawer-open {
  right: 0;
  top: 0;
}

.drawer-close {
  right: -500px;
  top: 0;
}
