@import "../../../common/styles/mixins";

.bn-pagination {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;

  @include mobile() {
      margin-top: 24px;
  }

  &__search_wrapper {
    margin-left: 24px;
    display: flex;
    align-items: center;
  }
  &__search {
    margin: 0 0 0 8px;
    padding: 0 0 0 8px;
    height: 32px;
    width: 50px;
    background: var(--Gray-gray-1);
    border: 1px solid var(--Gray-gray-5);
    border-radius: 2px;
    line-height: 30px;
    &:focus {
      outline: none;
    }
  }
}

.pagination {
  display: flex;
  list-style: none;

  .page-item.active .page-link {
    color: var(--accent-main);
    border-color: var(--accent-main);
    background-color: var(--white);
  }

  .page-item.disabled .page-link {
    color: var(--Gray-gray-7);
    pointer-events: none;
    background-color: var(--white);
    border-color: var(--Gray-gray-4);
    svg, path {
      fill: var(--Gray-gray-4);
    }
  }
  .page-link:hover {
    background-color: var(--Gray-gray-2) !important;
  }
  .page-link {
    padding: 0 0.7rem;
    height: 32px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--Gray-gray-9);
    text-decoration: none;
    background-color: var(--white);
    border: 1px solid var(--Gray-gray-5);
    border-radius: 2px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .page-item:not(:first-child) {
    margin-left: 8px;
  }

  [role="button"] {
    cursor: pointer;
  }
  [aria-label='Next page'] {
    transform: rotate(180deg);
  }
}
