@import "../../../../../../src/common/styles/variables.scss";
@import "../../../../../../src/common/styles/mixins";

// using bottomSheet.js
.spinner-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 14px;
  z-index: 7;
  background:var(--white);
}

.hovered {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.sidebar {
  &_wrapper{
    font-family: var(--roboto);
    display: flex;
    justify-content: center;
    color: var(--Gray-gray-9, #232325);
  }
  &_inner-wrapper{
    min-height: 100vh;
    position: relative;
    max-width: 464px;
    width: 100%;
    background: var(--white, #fff);
    box-shadow: -10px 0px 30px 0px rgba(0, 0, 0, 0.10);
    font-family: var(--roboto);
    @include mobile() {
      max-width:100%;
      background: var(--Gray-light);
    }
  }

  &_head{
    &__wrapper{
      margin-bottom: 16px;
      padding: 0 32px;
      @include mobile() {
        display: none;
      }
      h3{
        font-size: 20px;
        font-family: var(--roboto-med);
        line-height: 28px;
      }
      .copy-content {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 14px;
        //Todo
        text-align: start;
      }
    }
  }

  &_main-info{
    &__inner-wrapper{
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 32px;

      .delete-transfer {
        margin-top: 0;
        padding-right: 32px;
      }
      @include mobile() {
        gap: 0;
        margin-bottom: 32px;
      }
    }
  }

  &_content{
    &__wrapper{
      position: relative;
    }
  }


  &_list{
    &__wrapper{
      --column-hash-width: 32%;
      --column-value-width: 31%;
      --column-counterparty-width: 120px;
      --column-actions-width: 32px;
      --column-arrow-size: 16px;
      --column-hash-mobile-width:77%;
      --column-value-mobile-width:53%;

      .filter{
        &_wrapper{
          background: var(--white, #fff);
          padding-left: 16px;
          margin-bottom: 9px;
          @include mobile() {
            background: var(--Gray-light);
          }
        }
        &_title__wrapper{
          display: flex;
          justify-content: space-between;
          h3{
            font-size: 16px;
            font-family: var(--roboto-med);
            line-height: 24px;
            text-transform: capitalize;
          }
          .button{
            &._sort{
              color: var(--Gray-gray-7, #8C8D94);
              font-size: 12px;
              line-height: 22px;
            }
          }
        }
        &_tags__wrapper{
          display: flex;
          overflow-y: auto;
          gap: 8px;
          margin-top: 16px;
          .ant-tag{
            display: inline-flex;
            gap: 4px;
            margin: 0;
            padding: 2px 4px 2px 8px;
            border-radius: 2px;
            border: 1px solid var(--Gray-gray-4, #E8E9F0);
            background: var(--Gray-gray-2, #F3F4F9);
            color: var(--Gray-gray-8, #626368);
            font-size: 14px;
            line-height: 24px;
            max-width: 100%;
            .content{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &-close-icon{
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 0 0 auto;
            }
          }
        }
      }

      .list{
        &_head{
          &__wrapper{
            position: sticky;
            top: $close-section-height-small;
            z-index: $z-index-list-head;
            background: var(--white, #fff);
            @include mobile() {
              position:unset;
            }
          }
          &__inner-wrapper{
            display: flex;
            border-bottom: 1px solid var(--Gray-gray-3);
            @include mobile() {
              display: none;
            }
            .item_column{
              color: var(--Gray-gray-8, #626368);
              font-size: 12px;
              line-height: 21px;
              text-transform: uppercase;
            }
          }
        }
        &_body__wrapper{
          margin-top: 11px;
          position: relative;
          font-family: var(--roboto);

          .loader-container {
            height: 192px;
            position: relative;
            width: 100%;
            background-color: var(--Gray-gray-1);
            @include mobile() {
              background-color: var(--Gray-light);
            }

            .loading {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              .custom-spinner {
                position: unset;
              }
            }
          }
        }
      }

      .item{
        &_wrapper{
          position: relative;
          @include mobile() {
            border-top: 1px solid var(  --Gray-gray-3);
            &:nth-child(2n) {
              margin-bottom: 8px !important;
              border-bottom: 1px solid var(--Gray-gray-3);
              background: var(--Gray-gray-1);
            }
          }
          &:not(:last-child){
            margin-bottom: 8px;
            @include mobile() {
              margin-bottom: 0;
            }
          }
          &:not(.top) {
            &:before{
              position: absolute;
              content: "";
              top: 0;
              left: 0;
              width: calc(var(--column-hash-width) + var(--column-value-width));
              height: 100%;
              background: linear-gradient(90deg, rgba(236, 240, 250, 0.31) 0%, rgb(237, 241, 250) 100%);
              clip-path: polygon(calc(100% - #{var(--column-arrow-size)}) 0%, 100% 50%, calc(100% - #{var(--column-arrow-size)}) 100%, 0 100%, 0 0);
              z-index: 1;
              @include mobile() {
                width:var(--column-value-mobile-width)
              }
            }
          }
          &.top {
            display: flex;
            justify-content: space-between;
            padding: 10px 14px 7px 3px;
            background: var(--Gray-gray-1);
          }
          &._active{
            &:before{
              right: 0;
              left: unset;
              width: calc(var(--column-counterparty-width) + var(--column-actions-width) + 23px);
              background: linear-gradient(90deg, rgb(255, 238, 246) 0%, rgb(248, 248, 248) 100%);
              clip-path: polygon(var(--column-arrow-size) 0%, 100% 0, 100% 100%, var(--column-arrow-size) 100%, 0% 50%);
              @include mobile() {
                width: var(--column-value-mobile-width);
              }
            }
          }
          p{
            margin-bottom: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            line-height: 18px;
            text-align: start
          }
          .counterparty-entity{
            font-family: var(--roboto-med);
            color: var(--Gray-gray-9, #232325);
            @include mobile() {
              font-size: 14px;
            }
          }
          span{
            font-size: 12px;
            line-height: 16px;
            color: var(--Gray-gray-7, #8C8D94);
          }

          .button{
            background: transparent;
            cursor: pointer;
            height: 100%;
            width: 32px;
            flex: 0 0 auto;
            transition: 0.2s;
            .iconPlus path {
              transition: 0.2s;
              fill: #B9BBC2;
            }
            .iconCheck path {
              transition: 0.2s;
              fill: #59BD83;
            }
            .eyeOpen path {
              transition: 0.2s;
              fill:#B9BBC2;
            }
            .custom-spinner {
              span {
                font-size: 15px;
              }
            }
          }
        }

        &_inner-wrapper{
          position: relative;
          display: flex;
          gap: 8px;
          padding: 5px 0 6px 0;
          z-index: 2;
          cursor: pointer;
          @include mobile() {
            padding: 6px 0;
          }
          &._hover{
            .button{
              &:not(._active){
                .iconPlus path{
                  fill: var(--pink, #D64686);
                }
              }
            }
          }
        }

        &_column{
          display: flex;
          flex-direction: column;
          justify-content: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          z-index: 2;
          &._hash{
            width: var(--column-hash-width);
            padding-left: 16px;
            @include mobile() {
              width: var(--column-hash-mobile-width);
            }
          }
          &._value{
            width: var(--column-value-width);
            padding-left: 16px;
            @include mobile() {
              width: var(--column-value-mobile-width);
            }
            .amount {
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
            }
            span {
              .usd-value {
                font-size: 12px;
              }
            }
          }
          &._counterparty{
            flex: 0 0 auto;
            width: var(--column-counterparty-width);
            padding-left: 18px;
            @include mobile() {
              width: var(--column-value-mobile-width);
              flex: unset;
              padding-left: 40px;
            }
            span {
              max-width: 102px;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
              @include mobile() {
                text-overflow:unset;
              }
            }
          }
          &._actions{
            position: relative;
            width: var(--column-actions-width);
            flex: 0 0 auto;
            button {
              padding: 0;
              .custom-spinner {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
                svg path {
                  fill: var(--accent-main);
                }
              }
            }
          }
        }
      }

    }

    &__inner-wrapper{
      padding: 0 16px;
      @include mobile() {
        padding: 0;
      }
    }
  }

}

