@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {
  font-size: $size;
  line-height: $lineHeight;
  font-weight: $weight;
  font-family: 'Roboto';
}

@mixin maxHeight() {
  height: calc(100vh - 7px);
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1224px) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: 1440px) {
    @content;
  }
}

