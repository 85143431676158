
@import "../../../../common/styles/mixins";

.ant-drawer-body {
  padding: 16px !important;
}

.drawer {
  padding-top: 16px;

  @include mobile() {
    padding-top: 0;
  }
  .ant-tabs-tab {
    @include font(12px, 400, 24px);
    padding: 3px 15px;
    margin: 0 !important;
  }
  &__transfer {
    height: 100%;
    @include mobile() {
      background: var(--Gray-light);
    }
  }

  &__header {
    padding-left: 16px;
    margin-bottom: 24px;
    @include mobile() {
      display: none;
    }

    h4 {
      color: var(--Gray-gray-9, var(--text));
      @include font(20px, 500, 28px);
      text-transform: capitalize;
      font-family: var(--roboto-med);
    }
    p {
      color: var(--Gray-gray-9, var(--text));
      @include font(14px, 400, 22px);
      align-items: center;
      display: flex;
      svg {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  &__table_row:first-child {
    .drawer__table_row_wrapper:first-child {
      display: none;
      @include mobile() {
        display:flex;
      }
    }
  }
  &__table_row {
    margin-bottom: 24px;
    @include mobile() {
      margin: 0 10px;

    }
     &:nth-last-child(2) {
       .drawer__table_row_wrapper:last-of-type {
        border-bottom: none;
      }
    }

    &_wrapper {
      border-bottom: 1px solid var(--Gray-gray-3);
      display: flex;
      padding: 5px 16px 4px 16px;
      justify-content: space-between;
      align-items: center;

      @include mobile() {
        padding: 8px 16px;
      }

      b {
        color: var(--Gray-gray-9);
        text-align: right;
        @include font(14px, 500, 22px);
        font-family: var(--roboto-med);
        display: block;
      }

      span {
        color: var(--Gray-gray-8);
        text-transform: uppercase;
        @include font(12px, 400, 22px);

        &:last-child {
          text-transform: none;
          font-size: 14px;
          color: var(--Gray-gray-9);
          text-align: right;
          @include font(14px 400, 22px);
        }
        em {
          color: var(--Gray-gray-8);
          margin-left: 4px;
        }
      }
      .entity {
        max-width: 70%;
        .copy-content {
          word-break: break-all;
          text-align: end;
        }
      }
    }
  }
}
.delete-transfer {
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 49px;
  padding-right: 16px;
  @include mobile() {
    display: none;
  }

  .delete-content {
    color: var(--red-6);
    border-bottom: 1px solid var(--red-6);
    display: flex;
    @include font(14px, 400, 21px);
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    svg {
      margin-right: 4px;
    }
  }
}

