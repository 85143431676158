@import "../../../common/styles/mixins";

.initial-search-panel {
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px 0;

  .init-new-project {
    width: 160px;
    height: 32px;
    span {
      color: var(--white);
    }
  }

  input {
    border: none;
    width: 100%;
    height: 100%;
    display: block;
    background: transparent;
    position: relative;
    outline: none;
    padding: 0 18px 0 6px;
    font-family: var(--roboto);
    @include font(14px, 400, 22px);
    color: var(--Gray-gray-9);

    &::placeholder {
      color: var(--Gray-gray-6);
      font-family: Roboto;
      font-family: var(--roboto);
      @include font(14px, 400, 22px);
    }
  }
}
