@font-face {
  font-family: 'Roboto-bold';
  src: url(../fonts/roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: 'Roboto';
  src: url(../fonts/roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Roboto-medium';
  src: url(../fonts/roboto/Roboto-Medium.ttf);
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url(../fonts/roboto/Roboto-Regular.ttf);
}

:root {
  --Gray-gray-1: #FFFFFF;
  --Gray-gray-2: #F3F4F9;
  --Gray-gray-3:#EEEFF3;
  --Gray-gray-4: #E8E9F0;
  --Gray-gray-5: #D7D8E0;
  --Gray-gray-6: #B9BBC2;
  --Gray-gray-7: #8C8D94;
  --Gray-gray-8: #626368;
  --Gray-gray-9: #232325;
  --Gray-light:#FAFAFC;
  --Neutral-1: #fff;
  --text-color: #626368;
  --red-6: #E5424C;
  --pink: #D64686;
  --errorTextColor: #ff4d4f;
  --accent-main: #748ADA;
  --white: #fff;
  --text: #232325;
  --roboto: 'Roboto';
  --roboto-bold: 'Roboto-bold';
  --roboto-med: 'Roboto-medium';
  --roboto-reg:'Roboto-Regular';
  --table-th-color: #f3f4fa;
  --blue-2:#99ACF2;
}

$transition: 0.2s;

$close-section-height: 48px;
$close-section-height-small: 48px;

$z-index-close-section: 3;
$z-index-list-head: 4;
