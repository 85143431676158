@import '../../styles/mixins';
.ant-input {
  border-radius: 2px;
  border: 1px solid var(--Gray-gray-5, #D7D8E0);
  background: var(--Neutral-1, #FFF);
  padding: 8px;
}

.ant-form-item {
  .ant-form-item-label > label {
    @include font(14px,400,18px);
    font-family: var(--roboto);
    color: var(--Gray-gray-9);
  }
}
