@import "../../common/styles/mixins";

.ant-dropdown-menu {
  border-radius: 2px!important;
}
.ant-dropdown {
  width: auto !important;
}

.initial {
  height: 100dvh;
  width: 100%;
  background: transparent url("../../common/images/rocket.png") no-repeat 80% 100%;
  display: flex;
  justify-content: center;

  @include mobile() {
    background-position: -15% 90%;
    background-size: 50%;
  }
}

.initial-table {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;

  @include mobile() {
    height: auto;
    padding-bottom: 48px;
  }
}

#footer {
  clear: both;
  width: 100%;
  position: absolute;
  bottom: 40px;
}
