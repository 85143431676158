@import '../../../../../../../common/styles/mixins';

.table-row-group_wrapper{
  padding: 0 16px;
  @include mobile() {
    padding: 0 8px;
  }
  &:nth-child(4){
    .table-row_wrapper{
      &:last-child{
        border-bottom: 0;
      }
    }
  }
}
.table-row-group_wrapper:first-child {
  .table-row_wrapper:first-child {
    display: none;
    @include mobile() {
      display: flex;
    }
  }
}

.table-row{
  &_wrapper{
    display: flex;
    justify-content: space-between;
    padding: 5px 16px 4px 16px;
    border-bottom: 1px solid var(--Gray-gray-3);
    line-height: 22px;
    gap: 16px;
    &._bold-value{
      .table-row_value{
        font-family: var(--roboto-med), Arial , sans-serif;
        font-weight: normal;
      }
    }
    &._dark-title{
      .table-row_title{
        color: var(--Gray-gray-9, #232325);
      }
    }
  }
  &_title{
    text-transform: uppercase;
    color: var(--Gray-gray-8, #626368);
    font-size: 12px;
    line-height: 22px;
  }
  &_value{
    flex: 0 0 auto;
    font-size: 14px;
    display: flex;
    line-height: 22px;
  }
}
