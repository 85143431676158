._sheet {
  z-index: 999 !important;
  .react-modal-sheet-header {
    height: 20px !important;
    span {
      width: 24px !important;
    }
  }
  .react-modal-sheet-container  {
    border-radius: 16px 16px 0 0 !important;
  }
  .head{
    &-scroll {
      border-bottom: 1px solid var(--Gray-gray-4);
      border-radius: 16px 16px 0 0 !important;
      background: var(--Gray-light);
    }
    &-default {
      background: var(--Gray-light);
      border-radius: 16px 16px 0 0 !important;
    }
  }
  .content-top {
    display: flex;
    justify-content: space-between;
    svg {
      &:first-child {
        padding: 0 25px 16px 16px ;
      }
      &:last-child {
        padding: 0 16px 16px 25px;
      }
    }
    h4 {
      font-family: var(--roboto-med);
      padding-top: 3px;
    }
    &__transfer {
      text-transform: capitalize;
    }
  }
}
