@import "../../../../common/styles/mixins";

.search-panel {
  position: relative;
  background: var(--Gray-gray-2, var(--Gray-gray-2));
  z-index: 4;

  .wrapper {
    position: absolute;
    top: 96px;
    left: 120px;
    display: flex;
    justify-content: space-between;
    z-index: 1;

    &.open {
      width: 100%;
      @include mobile() {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    @include mobile() {
      left: 0;
      top: 72px;
      z-index: 1;
    }
  }
  .search-closed {
    @include mobile() {
      margin: 0 24px;
    }
  }
  .search-block {
    cursor: default;

    @include mobile() {
      width: calc(100% - 32px);
    }

    input {
      height: 32px;
      margin-bottom: 10px;
      @include font(14px, 400, 22px);
      padding-right: 30px;
      border-bottom: 1px solid var(--Gray-gray-5);
      border-radius: 2px 2px 0 0;
      background-color: var( --Gray-light);
    }

    .search-input {
      margin-bottom: 8px;
      border: none;
      cursor: default;
      transition: 0.35s ease-out;
    }
  }

  .search-closed {
    .list {
      display: none;
    }
    input {
      display: none;
    }

    div.search-input {
      width: 32px!important;
      cursor: pointer;
      height: 32px;
      margin-bottom: 0;
      background: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);

      svg {
        position: static;
      }
    }
  }

  .list {
    width: 385px;
    max-height: 192px;
    flex-shrink: 0;
    overflow: auto;
    border-radius: 0 0 8px 8px;
    background: var(--Gray-gray-1, var(--white));
    box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.10);
    &.mix {
      max-height: 246px;
      .search-items-block:nth-child(2) {
        .search-items-block__header {
          margin-top: 10px;
        }
      }
      .search-items-block__header {
        margin-top: 16px;
        @include mobile() {
          margin-top: 10px;
        }
      }
    }
    @include mobile() {
      width: 100%;
      max-height: 190px;

    }
  }

  .search-items-block {
    &__header {
      display: flex;
      gap: 4px;
      align-items:center;
      margin-top: 10px;
      margin-left: 17px;
    }
    .dot {
      height: 4px;
      width: 4px;
      background-color: var(--Gray-gray-6);
      border-radius: 50%;
      display: inline-block;
    }
    h4 {
      @include font(10px, 500, 14px);
      color: var(--Gray-gray-6);
      text-transform: uppercase;
      font-family: var(--roboto-med);
    }
  }

  .item:last-child {
    border-bottom: none;
  }

  .item {
    display: flex;
    width: 385px;
    height: 48px;
    padding: 4px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    border-bottom: 1px solid var(--Gray-gray-4);
    background: var(--Neutral-1, var(--white));
    pointer-events: auto;
    cursor: pointer;
    @include mobile() {
      width: 100%;
    }

    .spinner {
      right: -35px;
      @include mobile() {
        right: -31px;
      }
    }

    .spinner {
      right: 0;
    }
    div {
      display: flex;
      &:first-child {
        width: 63%;
        justify-content: space-between;
      }
      &:last-child {
        width: 48%;
        justify-content: flex-end;
        align-items: center;

        .hint-content {
          width: 112px !important;
          div {
            width: 100% !important;
          }
          @include mobile() {
            width: 84px !important;
          }
        }

      }
    }
    .search-transfer {
      p {
        @include font(12px, 400, 14px);
      }
    }

    p {
      @include font(14px, 400, 18px);
      margin: 0;
      font-family: var(--roboto);
      padding: 0;
      text-align: left;
      flex-shrink: 1;
      @include mobile() {
        @include font(12px, 500, 16px);
      }
    }
    &__data-address {
      font-size: 14px;
    }

    svg {
      flex-shrink: 1;
    }

    svg.add {
      path {
        transition: 0.3s ease-in-out;
        fill: var(--Gray-gray-7);
      }
    }
    .select  {
      width: 20px;
      height: 20px;
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M6.56834 13.9303L2.81496 10.1769C2.71681 10.0776 2.59992 9.99871 2.47106 9.94487C2.3422 9.89104 2.20394 9.86332 2.06429 9.86332C1.92463 9.86332 1.78637 9.89104 1.65751 9.94487C1.52865 9.99871 1.41176 10.0776 1.31361 10.1769C1.21426 10.2751 1.13539 10.392 1.08155 10.5208C1.02772 10.6497 1 10.788 1 10.9276C1 11.0673 1.02772 11.2055 1.08155 11.3344C1.13539 11.4632 1.21426 11.5801 1.31361 11.6783L5.80694 16.1716C6.22517 16.5898 6.90078 16.5898 7.31902 16.1716L18.6864 4.81496C18.7857 4.71681 18.8646 4.59992 18.9184 4.47106C18.9723 4.3422 19 4.20394 19 4.06429C19 3.92463 18.9723 3.78637 18.9184 3.65751C18.8646 3.52865 18.7857 3.41176 18.6864 3.31361C18.5882 3.21426 18.4713 3.13539 18.3425 3.08155C18.2136 3.02772 18.0754 3 17.9357 3C17.7961 3 17.6578 3.02772 17.5289 3.08155C17.4001 3.13539 17.2832 3.21426 17.185 3.31361L6.56834 13.9303Z" fill="%2359BD83"/></svg>');
    }

    b {
      flex-shrink: 1;
      @include font(14px, 500, 18px);
      padding-right: 8px;
      font-family: var(--roboto-med);
    }
  }
  .item:has(.no-data) {
    pointer-events: none;
  }
  .item:hover {
    background: var( --Gray-light);
    .select {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M7.73437 10C7.73437 10.5802 7.96483 11.1366 8.37507 11.5468C8.78531 11.957 9.3417 12.1875 9.92187 12.1875C10.502 12.1875 11.0584 11.957 11.4687 11.5468C11.8789 11.1366 12.1094 10.5802 12.1094 10C12.1094 9.41984 11.8789 8.86344 11.4687 8.4532C11.0584 8.04297 10.502 7.8125 9.92187 7.8125C9.3417 7.8125 8.78531 8.04297 8.37507 8.4532C7.96483 8.86344 7.73437 9.41984 7.73437 10ZM18.4023 9.49609C16.5508 5.5957 13.7519 3.63281 9.99999 3.63281C6.24608 3.63281 3.44921 5.5957 1.59765 9.49805C1.52338 9.65531 1.48486 9.82706 1.48486 10.001C1.48486 10.1749 1.52338 10.3466 1.59765 10.5039C3.44921 14.4043 6.24804 16.3672 9.99999 16.3672C13.7539 16.3672 16.5508 14.4043 18.4023 10.502C18.5527 10.1855 18.5527 9.81836 18.4023 9.49609ZM9.92187 13.4375C8.02343 13.4375 6.48437 11.8984 6.48437 10C6.48437 8.10156 8.02343 6.5625 9.92187 6.5625C11.8203 6.5625 13.3594 8.10156 13.3594 10C13.3594 11.8984 11.8203 13.4375 9.92187 13.4375Z" fill="%23D64686"/></svg>');
    }
    svg.add {
      path {
        fill: var(--pink);
      }
    }
  }
}
