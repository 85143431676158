@import '../../../../common/styles/mixins';

.auth {
  position: relative;
  height: 100dvh;
  width: 100%;
  background: var(--Gray-gray-1);
  display: flex;
  justify-content: center;
  @include mobile() {
    padding: 16px;
    background-color: var( --Gray-gray-2);
    height: calc(100dvh - 32px);
    width: auto;
  }
  &-content {
    justify-content: center;
    height: 100vh;
    width: 588px;
    @include mobile() {
      width: 100%;
      height: 100%;
    }
  }
  &-logo {
    &__by-bitok {
      position: absolute;
      display: flex;
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      margin-top: 48px;
      svg {
        height: 22px;
      }
    }
  }
  &-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 48px;
    padding: 64px 102px 48px 102px;
    background: var(--Gray-gray-3);
    border: 1px solid var(--Gray-gray-4);
    border-radius: 16px;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
    @include mobile() {
      padding: unset;
      background: unset;
      border: unset;
      border-radius: unset;
      height: 100%;
      justify-content: unset;
      margin-bottom: 0;
      transform: unset;
      top: unset;
      right: unset;
    }
    .select-language {
      margin-top: 24px;
      @include mobile() {
        margin: 26px 0 24px 0;
      }
      &__dropdown__container {
        padding-top: 0;
        line-height: 20px;
        color: var(--accent-main);
        svg:nth-child(2) {
          path{
            fill: var(--accent-main);
          }
        }
      }
    }
    &__title {
      margin-bottom: 28px;
      @include font(24px,500,32px);
      font-family: var(--roboto-med);
      @include mobile() {
        margin-top: calc((100dvh - 248px) / 2);;
        @include font(20px, 500,28px);
        font-family: var(--roboto-med);
        margin-bottom: 40px;
      }
    }
  }
  &-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    &__input {
      font-size: 16px;
      height: 40px;
      border-radius: 2px;
      @include mobile() {
        font-size: 14px;
        padding: 4px 11px !important;
      }
      .ant-input-password-icon {
        display: flex;
        cursor: pointer;
      }
    }

    .ant-form-item {
      margin-bottom: 16px;
    }

    div {
      width: 100%;
      .validate {
        width: 100%;
        color: var(--errorTextColor);
        font-size: 14px;
        transform: translateY(-70%);
        line-height: 22px;
        transition: .5s;
        opacity: 0;
        margin-bottom: 0;
        text-align: start;
      }

      .validate-active {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;
        transition: .5s;
      }

      .ant-form-item {
        margin-bottom: 16px;
        &-label {
          margin-bottom: 8px;
          text-align: start;
          padding: 0;
          line-height: 18px;
          label {
            height: 18px;
            &::after {
              content: none;
            }
          }
        }
        &-additional {
          min-height: 0 !important;
        }
      }
    }
    &:has(.save-content) {
      @include mobile() {
        min-height: 225px;
      }
    }
    @include mobile() {
      position: relative;
      height: calc(100% - 146px);
      min-height: 305px;
      justify-content: unset;
    }
    .field-wrapper {
      width: 100%;
    }
    .save-container,
    .save-content {
      width: 100%;
      @include mobile() {
        height: 80px;
        display: flex;
        justify-content: end;
        align-items: end;
        width:100%;
        position: absolute;
        bottom: 0;
      }
    }
    &__btn {
      margin-top: 24px;
      width: 100%;
      height: 40px;
      @include font(14px,400,24px);
      border-radius: 2px;
      background: var(--pink);
      color: var(--Gray-gray-1);
      @include mobile() {
        margin-top: 0;
      }
      &:disabled {
        border: 1px solid var(--Gray-gray-5);
        background: var(--Gray-gray-2);
        color: var(--Gray-gray-6);
      }
    }
  }
  &-imgs {
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    &__okman-big {
      position: absolute;
      top: -250px;
      left: 250px;
      width: 308px;
      height: 300px;
    }
    &__okman-small {
      position: absolute;
      left: -650px;
      bottom: -300px;
      width: 195px;
      height: 197px;
    }
  }
}
.ant-form-item-control {
  flex: none !important;
}
