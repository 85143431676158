@import "../../../common/styles/mixins";

// DROPDOWN styles
.ant-dropdown-menu {
  padding: 4px 0!important;
  top: 5px;
  &-item {
    @include mobile() {
      padding: 11px 12px !important;
    }
  }
  &-title-content  {
    &:has(svg) {
      display: flex;
      svg {
        margin-right: 8px;
      }
    }
    span {
      @include font(14px,400,18px);
      font-family: var(--roboto);}
  }
  @include mobile() {
    top: 0;
  }
}

.ant-layout {
  &-header {
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    align-items: center;
    height: 40px;
    padding: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    background: var(--Gray-gray-2, #F3F4F9);
    border-bottom: 1px solid var(--Gray-gray-4, #E8E9F0);

    @include mobile() {
      background: var(--Gray-gray-1);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      height: 56px;
    }
    .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 1240px;
      padding: 0 20px;
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
      margin: 0 auto;

      @include mobile() {
        width: 100%;
        padding: 0;
      }
    }
    .wrapper:has(.main-logo) {
      .header-right {
        @include mobile() {
          width: 55px;
        }
      }
    }

    .menu {
      @include font(16px,400,24px);
      color: var(--Gray-gray-8);
      cursor: pointer;
      @include mobile() {
        display: none;
      }
    }
    .menu:hover {
      color: var(--Gray-gray-9);
    }

    .logo {
      display: flex;
      align-items: center;
      color: var(--Gray-gray-9);
      @include font(14px,500,22px);
      font-family: var(--roboto-med);
      @include mobile() {
        width: 55px;
      }
    }
    .logo:hover {
      svg path {
        fill: var( --Gray-gray-7);
      }
      color: var( --Gray-gray-7);
    }

    .edit-wrapper {
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        color: var(--text);
        @include font(14px, 500, 22px);
        font-family: var(--roboto-med), sans-serif;
        @include mobile() {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          text-align: center;
        }
      }
      svg  {
        cursor: pointer;
      }
    }

    .logo,
    .edit-wrapper,
    .header-right, {
      height: 48px !important;
      align-self: center;
    }
    .edit-wrapper {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .header-right {
      justify-self: end;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 23px;
      div:nth-child(2) {
        display: flex;
        height: 100%;
        align-items: center;
      }
    }
  }
}
