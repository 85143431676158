@import "../../../../../common/styles/mixins";

.show-more__skeleton {
  width: 100% !important;
  height: 48px !important;
  border: 0.5px solid var(--Gray-gray-5);
  border-radius: 2px 2px 16px 16px !important;

  @include mobile() {
    border-radius: 2px !important;
    height: 40px !important;
  }
}
.show-more-container {
  margin-top: 8px;
  @include mobile() {
    margin-top: 24px;
    width: 91.1%;
  }
}
