@import "../../../styles/mixins";

.modal {
  &-wrapper {
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  &-content {
    width: 460px;
    margin: 24px auto 32px;
    @include mobile() {
      width: 85%;
    }

    span.label {
      color: var(--Gray-gray-9);
      font-family: var(--roboto);
      margin-bottom: 5px;
      display: block;
      @include font(14px, 400, 18px);
    }

    button {
      width: 100%;
    }

    textarea {
      color: var(--Gray-gray-9);
      font-family: var(--roboto);
      @include font(16px, 400, 24px);
      border-radius: 2px;
      border: 1px solid var(--Gray-gray-5);
      background: var(--Neutral-1);
      margin-bottom: 24px;
    }
  }

  &-header {
    width: 636px;
    gap: 10px;
    height: 76px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--Gray-gray-2, #F3F4F9);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    @include mobile() {
      width: 100%;
    }

    p {
      margin: 0;
      padding: 0;
      color: var(--Gray-gray-9);
      font-family: var(--roboto);
      @include font(20px, 400, 28px);
    }

    span {
      position: absolute;
      top: 50%;
      cursor: pointer;
      margin-top: -8px;
      right: 20px;
    }
  }
}
