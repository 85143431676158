@import "../../../common/styles/mixins";

.toolbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: transparent;
  border: none;
  transition: all 0.25s ease-in-out;

  ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
    padding: 0;
    margin: 0;
  }

  li {
    width: 32px;
    height: 40px;
    border-radius: 2px;
    border-bottom: 1px solid var(--Gray-gray-4);
    background: var(--white);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
  }
  li:hover {
    svg {
      path ,
      rect,
        {
        fill:var(--pink);
      }
    }
  }

  .zoom-number {
    cursor: default;
  }
  .equivalent-wrapper {
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.10);
    .select-brush {
      @include mobile() {
        display: none;
      }
      &:hover {
        svg {
          path {
            stroke:var(--pink);
            fill: var( --Gray-gray-1);
          }
        }
      }
      &.active {
        background: var(--pink);
        svg {
          path {
            stroke:var( --Gray-gray-1);
            fill:var(--pink) ;
          }
        }
      }
    }
  }

  .zoom-wrapper, .view-wrapper {
    display: flex;
    width: 32px;
    background: var(--white);
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    justify-content: center;
    border-radius: 2px;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.10);
  }
  .view-wrapper li:hover {
    svg {
      path,circle {
        stroke: var(--pink);
      }
    }
  }
}

.toolbar-open {
  top: 64px;
  right: 475px;
  z-index: 4;
}

.toolbar-closed {
  top: 220px;
  right: 120px;
  z-index: 4;

  @include mobile() {
    top: 216px;
    right: 16px;
  }
}
