@import "../../../common/styles/mixins";

.block {
  position: relative;

  @include mobile() {
    button {
      width: 328px;
      height: 48px;
    }
  }
  &__container {
    width: 282px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      @include font(16px, 400, 24px);
      text-align: center;
      margin-bottom: 24px;
    }

    @include mobile() {
      width: fit-content;
    }
  }
}

