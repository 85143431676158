.sidebar_list__inner-wrapper {
  .button{
    transition: 0.2s;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    &:not(:disabled){
      cursor: pointer;
      padding: 0;
    }
  }
}