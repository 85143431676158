@import "src/common/styles/mixins";
.default-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px !important;
  height: 25px;
}

.custom-spinner {
  position: absolute;
  top: 5px;
  right: 6px;
  svg {
    position: unset;
  }
  @include mobile() {
    right: 22px;
  }
}
