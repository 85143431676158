@import "./mixins";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  background: var(--Gray-light);
}
body:has(.drawer-layout.drawer-open) {
  overflow: hidden;
}
.no-scroll {
  overflow: hidden;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ant-layout-content {
  padding: 0;
}
* {
  user-select: none;
}

.ant-layout-content:nth-child(2) {
  &:has(canvas) {
    height: 100dvh;
  }
  min-height: 100dvh;
  padding-bottom: 80px;
  position: relative;
  &:has(.drawer__transfer) {
    .toolbar-open {
      right: 444px;
    }
  }
}
.ant-layout-content:has(.initial) {
  height: 100dvh;
}


