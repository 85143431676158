.search-input {
  border-bottom: 1px solid var(--Gray-gray-5);
  height: 32px;
  position: relative;

  input {
    width: 100%;
    border: none;
    background: transparent;
    flex-shrink: 0;
    padding-left: 5px;
    margin-bottom: 12px;

    color: #232325;
  }

  input:focus, input:hover, input:active, input:focus-visible {
    box-shadow: none;
    outline: none;
  }

  .remove-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg, i {
    position: absolute;
    top: 5px;
    display: block;
    right: 5px;
  }
  .search-close-icon {
    position: absolute;
    top: 3px;
  }

  svg.clear {
    z-index: 2;
  }
}
