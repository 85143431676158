@import "../../../common/styles/mixins";

.button {
  @include font(14px, 400, 24px);
  height: 40px;
  padding: 5px 16px 5px 16px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    border-color: #c6bebea8 !important;
  }

  svg {
    path {
      fill: var(--Gray-gray-1);
    }
  }
}
