@import "../../../common/styles/mixins";

.initial-detail-table-mobile {
  width: 100%;
  margin-top: 72px;

  b, p, i, span, div {
    margin: 0;
    padding: 0;
  }

  b {
    display: block;
    font-family: var(--roboto-med) !important;
    text-align: left;
    @include font(14px, 500, 22px);
    color: var(--Gray-gray-9);
    padding: 0;
    margin: 0;
  }

  span {
    font-family: var(--roboto);
    text-align: left;
    color: var(--Gray-gray-8);
    @include font(14px, 400, 22px);
  }

  p {
    font-family: var(--roboto);
    color: var(--Gray-gray-9);
    @include font(14px, 400, 22px);
  }

  i {
    color: var(--Gray-gray-8);
    display: block;
    @include font(12px, 400, 16px);
    font-style: normal;
  }

  .item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-top: 1px solid var(--Gray-gray-4);
    padding: 6px 0 6px 16px;
  }

  .block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:first-child {
      align-items: start;
      b {
        max-width: 190px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
    &:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__with-icon {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }
}

.initial-detail-table {
  margin-top: 80px;
  width: 1240px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  @include mobile() {
    margin-top: 0;
    width: 100%;
    padding: 0;
    align-items: center;
  }

  .ant-table-thead tr th, .ant-table-column-title {
    background: var(--Gray-gray-2);
    color: var(--Gray-gray-9);
    font-family: var(--roboto-med) !important;
    @include font(14px);
  }

  h4 {
    align-items: center;
    display: flex;
    color: var(--Gray-gray-9);
    @include font(16px, 500, 24px);
    padding-left: 8px;
    margin-bottom: 16px;
    font-family: var(--roboto-med);
  }

  b {
    @include font(14px);
    display: block;
    font-family: var(--roboto-med);
    @include mobile {
      line-height: 22px;
    }
  }

  span {
    display: block;
    @include font(14px, 400,  22px);
    &:last-child {
      @include font(12px, 400,  16px);
      font-family: var(--roboto);
      @include mobile {
        line-height: 22px;
      }
    }
  }

  .grey {
    color: var(--text-color);
  }

  .ant-table-thead tr>th {
    padding: 11px 8px;
    border-start-start-radius: 0;
  }
  .ant-table-tbody tr {
    background-color: var(--Gray-gray-1);
  }
  .ant-table-tbody tr:hover {
    cursor: pointer;
    background-color: var(--Gray-light);
  }
  .ant-table-tbody tr {
    background-color: var(--Gray-gray-1);
  }
  .ant-table-tbody tr:hover {
    background-color: var(--Gray-light);
  }

  .ant-table-tbody tr>td {
    padding: 10px 8px;
    border-color: var(--Gray-gray-4);
  }

  tr td {
    vertical-align: middle;

    &:first-child {
      padding-left: 23px;
      word-break: break-word;
    }

    svg {
      cursor: pointer;
    }
  }

  .ant-table-column-has-sorters {
    background: var(--Gray-gray-2)!important;
  }

  tr th {
    background: var(--table-th-color);
    &:before {
      background-color: var(--Gray-gray-5)!important;
    }

    &:first-child {
      width: 320px;
      padding-left: 23px;
      border-start-start-radius: 0!important;
    }

    &:nth-child(2) {
      width: 200px;
      .ant-table-column-sorters {
        justify-content: start;
        span {
          display: block;
          flex: none;
        }
        .anticon svg {
          color: var(--Gray-gray-6);
        }
        .ant-table-column-sorter {
          margin-left: 6px;
        }
        .ant-table-column-sorter-inner {
          span {
            &:first-child {
              svg {
                margin-bottom: -4px;
              }
            }
            &:last-child {
              margin-top: -0.6em;
            }
          }
        }
      }

      span.active svg {
        color: var(--Gray-gray-8) !important;
      }
    }

    &:nth-child(3), &:nth-child(4) {
      width: 240px;
    }
    &:nth-child(5) {
      width: 150px;
      &:before {
        display: none;
      }
    }
    &:last-child {
      width: 50px;
      border-start-end-radius: 0!important;
    }
  }

  .table-footer {
    border-radius: 2px 2px 16px 16px;
    border: 1px solid var(--Gray-gray-4, #E8E9F0);
    background: var(--Gray-gray-2, #F3F4F9);
    height: 48px;
    padding: 5px 16px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
    @include mobile() {
      border-radius:2px;
      width: 91.1%;
      margin-top: 24px;
      height: 40px;
    }

    span {
      color: var(--accent-main);
      @include font(14px, 400, 24px);
      font-family: var(--roboto);
    }
  }
}


